// src/services/api.js

import axios from 'axios';

const API_BASE_URL = 'https://app.foxi.tips/api';

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('jwtToken');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor for handling errors
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 429) {
      console.error('Rate limit exceeded. Please try again later.');
    }
    return Promise.reject(error);
  }
);

export const userApi = {
  authenticateUser: (initData, startAppId) => api.post('/users/authenticate', { initData, startAppId }),
  getUserData: (userId) => api.get(`/users/data/${userId}`),
  signUp: (userData) => api.post('/users/signup', userData),
  getReferrals: (userId) => api.get(`/users/referrals/${userId}`),
  getDailyCheckInStatus: (userId) => api.get(`/users/daily-check-in/${userId}`),
  performDailyCheckIn: (userId, rewardType) => api.post(`/users/daily-check-in/${userId}`, { rewardType }),
  getLeagues: () => api.get('/users/leagues'),
  searchUsers: (query) => api.get('/users/search', { params: { 'search-query': query } }),
  activateFarm: (userId) => api.post('/users/activate-farm', { userId }),
  spinWheel: (userId) => api.post(`/users/spin-wheel/${userId}`),
  getTipHistory: (userId) => api.get(`/users/tip-history/${userId}`),
  uploadProfile: (data) => api.post('/users/upload-profile', data),
  checkWhitelistStatus: (userId) => api.get('/users/whitelist-status', { params: { userId } }),
};

export const taskApi = {
  getAllTasks: () => api.get('/tasks'),
  addTask: (taskData) => api.post('/tasks', taskData),
  checkTask: (userId, taskId) => api.get('/tasks/check-task', { params: { userId, taskId } }),
};

export const tipApi = {
  tipUser: (tipData) => api.post('/tips', tipData),
  tipUserWebapp: (tipData) => api.post('/tips/tip-webapp', tipData),
};

export const adminApi = {
  getAnalytics: () => api.get('/admin/analytics'),
  getAllUsers: () => api.get('/admin/all-users'),
  getEarlyUsers: () => api.get('/admin/early-users'),
  getRecentUsers: () => api.get('/admin/recent-users'),
};

export const miscApi = {
  getLeagues: () => api.get('/leagues'),
  getPrivateLaunchTime: () => api.get('/private-launch-time'),
  getPublicLaunchTime: () => api.get('/public-launch-time'),
};

export default api;